import React from 'react';
import { Link } from "gatsby"
import Layout from '../components/Layout';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1>Page Not Found</h1>
        <p>You just hit a page that doesn&#39;t exist...</p>
        <Link to="/">
          Go to Home Page
        </Link>     
      </Layout>
    );
  }
}

export default NotFoundPage;
